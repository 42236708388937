import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "react-toggle/style.css";
import { changeLanguage } from "../../actions/store";
import config from "../../config";
import { languages } from "../../constants/language";
import { useMenu } from "../../menu-items";

const Sidebar: React.FC = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const ossUrl = config.ossUrl + `${config.region}/Landing/`;
	const menu = useMenu();
	const url = window.location.href;
	const hostname = new URL(url).hostname;
	const domainName = hostname.replace(/^www\./, '');

	const currentLanguage = localStorage.getItem("Language") || "en";
	const [showLanguageModal, setShowLanguageModal] = useState(false);

	const handleLanguageSelect = (lang: string) => {
		dispatch(changeLanguage(lang));
		localStorage.setItem("Language", lang);
		setShowLanguageModal(false);
	};

	const [activeItem, setActiveItem] = useState(0);

	const handleItemClick = (item, index) => {
		if (item.type === 'action' && typeof item.value === 'function') {
			item.value();
		}
		setActiveItem(index);
	};

	return (
		<div id="sidebar" className="sidebar">
			<div className="sidebar-top">
				<a href="/">
					<Image src={"logo.png"} alt="logo" width={80} style={{ marginBottom: '-10px' }} />
					<br />
					{/* <h5 className="my-1">{domainName}</h5> */}
				</a>
				<hr className="hr-line" />
				<div className="lobbyTopContainer">
					<div className="lobbyTopItem">
						<img src="/icons/player.png" alt="Playericon" className="iconPlayer" />
						<span style={{ fontWeight: '500' }}>
							{t("Player")}
						</span>
					</div>
					<div className="lobbyTopDivider" />
					<a href="https://epaygame.net/admin/login" className="lobbyTopItem">
						<img src="/icons/member.png" alt="Membericon" className="iconMember" />
						<span style={{ color: '#898989' }}>
							{t("Member")}
						</span>
					</a>
				</div>
			</div>
			<div id="sidemenu">
				{menu.map((item, index) => {
					if (!item.access) {
						return null;
					}

					const isActive = index === activeItem;

					if (item.type === "link" && typeof item.value === "string") {
						return (
							<Link key={index} to={item.value} style={{ textDecoration: 'none' }}>
								<div key={index} className={`menu-div ${isActive ? 'active' : ''}`} onClick={() => handleItemClick(item, index)}>

									{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
									<Image
										src={ossUrl + "icons/" + item.id + ".png"}
										alt={item.id}
									/>
									{t(item.label)}

								</div>
							</Link>
						);
					} else if (
						item.type === "action" &&
						typeof item.value === "function"
					) {
						return (
							<div key={index} onClick={item.value} className="menu-div">
								{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					} else if (item.type === "dropdown") {
						return (
							<div key={index} className="menu-div"
								onClick={() => {
									setShowLanguageModal(true);
								}}
							>
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					}

					return null;
				})}
			</div>

			<Modal show={showLanguageModal} onHide={() => setShowLanguageModal(false)} className="modal-language">
				<Modal.Header >
					<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 15px' }}>
						<FontAwesomeIcon icon={faTimes} color="black" size="xl" onClick={() => setShowLanguageModal(false)} />
					</div>
					<div style={{ position: 'relative', width: '100%' }}>
						<div style={{ textAlign: 'center' }}>
							<p>{t("Select Language")}</p>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center">
					{languages.map((lang, index) => (
						<Button
							key={index}
							className="w-75 my-2"
							onClick={() => handleLanguageSelect(lang.value)}
							style={{
								backgroundColor: lang.value === currentLanguage ? 'rgb(255, 78, 0)' : 'rgb(87, 87, 87)',
								color: lang.value === currentLanguage ? '#000' : 'rgb(165, 165, 165)',
								border: `1px solid ${lang.value === currentLanguage ? 'rgb(255, 78, 0)' : 'rgb(87, 87, 87)'}`
							}}
						>
							{lang.label}
						</Button>
					))}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Sidebar;
