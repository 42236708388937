import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Image, Row, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toggle/style.css";
import config from "../../config";
import { isNullOrUndefined } from "../../utils";
import { changeLanguage } from "../../actions/store";
import { languages } from "../../constants/language";
import { useMenu } from "../../menu-items";

const Header: React.FC = () => {
	const { t } = useTranslation();
	const { userProfile } = useSelector((state: any) => state.userProfile);
	const ossUrl = config.ossUrl + `${config.region}/Landing/`;
	const dispatch = useDispatch();
	const menu = useMenu();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const currentLanguage = localStorage.getItem("Language") || "en";
	const [activeItem, setActiveItem] = useState(0);

	const handleItemClick = (item, index) => {
		if (item.type === 'action' && typeof item.value === 'function') {
			item.value();
		}
		setActiveItem(index);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const sticky = header?.offsetTop || 0;

		const scrollCallBack = () => {
			if (window.pageYOffset > sticky) {
				header?.classList.add("sticky");
			} else {
				header?.classList.remove("sticky");
			}
		};

		window.addEventListener("scroll", scrollCallBack);

		return () => {
			window.removeEventListener("scroll", scrollCallBack);
		};
	}, []);

	const headerStyle = isNullOrUndefined(userProfile)
		? { padding: "12px .5rem" }
		: {};

	const mobileSidebar = (
		<div
			className="offcanvas offcanvas-start"
			id="offcanvasExample"
			aria-labelledby="offcanvasExampleLabel"
			data-bs-dismiss="offcanvas"
		>
			<div className="sidebar-top">
				<div className="offcanvas-header justify-content-center text-center py-1">
					<a href="/">
						<Image src={"logo.png"} alt="logo" width={80} style={{ marginBottom: '-10px' }} />
						<br />
						{/* <h5 className="my-1"></h5> */}
					</a>
				</div>
				<hr className="hr-line-mobile" />
				<div className="lobbyTopContainer">
					<div className="lobbyTopItem">
						<img src="/icons/player.png" alt="Playericon" className="iconPlayer" />
						<span style={{ fontWeight: '500' }}>
							{t("Player")}
						</span>
					</div>
					<div className="lobbyTopDivider" />
					<a href="https://epaygame.net/admin/login" className="lobbyTopItem">
						<img src="/icons/member.png" alt="Membericon" className="iconMember" />
						<span style={{ color: '#898989' }}>
							{t("Member")}
						</span>
					</a>
				</div>
			</div>

			<div id="sidemenu" className="offcanvas-body m-0">
				{menu.map((item, index) => {
					if (!item.offCanvas) {
						return null;
					}

					const isActive = index === activeItem;

					if (item.type === "link" && typeof item.value === "string") {
						return (
							<Link key={index} to={item.value} style={{ textDecoration: 'none' }}>
								<div key={index} className={`menu-div ${isActive ? 'active' : ''}`} onClick={() => handleItemClick(item, index)}>

									{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
									<Image
										src={ossUrl + "icons/" + item.id + ".png"}
										alt={item.id}
									/>
									{t(item.label)}

								</div>
							</Link>
						);
					} else if (
						item.type === "action" &&
						typeof item.value === "function"
					) {
						return (
							<div key={index} onClick={item.value} className="menu-div">
								{/* <FontAwesomeIcon icon={item.icon} className="me-3" /> */}
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					} else if (item.type === "dropdown") {
						return (
							<div key={index} className="menu-div"
								onClick={() => {
									setShowLanguageModal(true);
								}}
							>
								<Image
									src={ossUrl + "icons/" + item.id + ".png"}
									alt={item.id}
								/>
								{t(item.label)}
							</div>
						);
					}

					return null;

				})}
			</div>
		</div>
	);

	return (
		<header id="myHeader" style={headerStyle}>
			<div className="header">
				{isMobile && (
					<div style={{ alignSelf: 'center' }}>
						<div
							data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasExample"
							aria-controls="offcanvasExample"
						>
							<FontAwesomeIcon icon={faBars} className="bar-icon" />
						</div>
						{mobileSidebar}
					</div>
				)}
				<div style={{ width: '100%', justifyItems: 'center' }}>
					<div className="logo-name">
						<p>EPAY</p>
					</div>
				</div>
				{/* {!isNullOrUndefined(userProfile) && (
					<Col xs="auto">
						<h6 className="m-0">
							{t("Welcome back")}, {userProfile.player_nickname}
						</h6>
					</Col>
				)} */}
			</div>

			<Modal show={showLanguageModal} onHide={() => setShowLanguageModal(false)} centered className="modal-language">
				<Modal.Header >
					<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 15px' }}>
						<FontAwesomeIcon icon={faTimes} color="black" size="xl" onClick={() => setShowLanguageModal(false)} />
					</div>
					<div style={{ position: 'relative', width: '100%' }}>
						<div style={{ textAlign: 'center' }}>
							<p>{t("Select Language")}</p>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center">
					{languages.map((lang, index) => (
						<Button
							key={index}
							className="w-75 my-2"
							onClick={() => {
								dispatch(changeLanguage(lang.value));
								localStorage.setItem("Language", lang.value);
								setShowLanguageModal(false);
							}}
							style={{
								backgroundColor: lang.value === currentLanguage ? 'rgb(255, 78, 0)' : 'rgb(87, 87, 87)',
								color: lang.value === currentLanguage ? '#000' : 'rgb(165, 165, 165)',
								border: `1px solid ${lang.value === currentLanguage ? 'rgb(255, 78, 0)' : 'rgb(87, 87, 87)'}`
							}}
						>
							{lang.label}
						</Button>
					))}
				</Modal.Body>
			</Modal>
		</header>
	);
};

export default Header;
